import React from 'react'
import { object, bool, string } from 'prop-types'
import { Grid, Section, Page } from 'cobalt-react-components'
import {
  DisclaimerHeader,
  DisclaimerBody,
  LoginHeader,
  TalkdeskFooter,
  LoginBody
} from '../../components'
import SideIllustration from '../../components/SideIllustration'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDisclaimer: this.props.disclaimerEnabled
    }
  }

  onDisclaimerAcknowledged = () => {
    this.setState({ showDisclaimer: !this.state.showDisclaimer })
  }

  render() {
    const {
      csrf,
      localLoginEnabled,
      identityProviders,
      showCaptcha,
      showGoogleFooterLink,
      siteKey,
      brandAssetsURL,
      brandingSettings,
      talkdeskIdAssetsURL,
      marketingBannerURL,
      marketingBannerTimeout,
      sidebannerEnabled
    } = this.props
    const hasIdentityProviders = Object.keys(identityProviders).length !== 0
    const loginContainerWidth = sidebannerEnabled ? '60' : '100'

    return (
      <Page>
        <Page.Content>
          <Grid noPadding fullHeight fullWidth>
            <Grid.Group fullHeight>
              <Grid.Column
                all={loginContainerWidth}
                medium="100"
                small="100"
                tiny="100"
              >
                <Section fullHeight>
                  <Section.Content>
                    <Grid pushCenter fullHeight>
                      <div className="container-width container-max-width">
                        {this.state.showDisclaimer ? (
                          <React.Fragment>
                            <DisclaimerHeader
                              brandingSettings={brandingSettings}
                            />
                            <DisclaimerBody
                              onAcknowledge={this.onDisclaimerAcknowledged}
                            />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <LoginHeader
                              localLoginEnabled={localLoginEnabled}
                              brandingSettings={brandingSettings}
                            />
                            <LoginBody
                              brandAssetsURL={brandAssetsURL}
                              brandingSettings={brandingSettings}
                              localLoginEnabled={localLoginEnabled}
                              csrf={csrf}
                              showCaptcha={showCaptcha}
                              siteKey={siteKey}
                              identityProviders={identityProviders}
                              hasIdentityProviders={hasIdentityProviders}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </Grid>
                  </Section.Content>
                  <TalkdeskFooter
                    brandingSettings={brandingSettings}
                    showGoogleFooterLink={showGoogleFooterLink}
                  />
                </Section>
              </Grid.Column>
              {sidebannerEnabled && (
                <Grid.Column all="40" medium="hide" small="hide" tiny="hide">
                  <SideIllustration
                    talkdeskIdAssetsURL={talkdeskIdAssetsURL}
                    marketingBannerURL={marketingBannerURL}
                    marketingBannerTimeout={marketingBannerTimeout}
                  />
                </Grid.Column>
              )}
            </Grid.Group>
          </Grid>
        </Page.Content>
      </Page>
    )
  }
}

LoginPage.propTypes = {
  csrf: object.isRequired,
  sidebannerEnabled: bool.isRequired,
  localLoginEnabled: bool.isRequired,
  siteKey: string.isRequired,
  talkdeskIdAssetsURL: string.isRequired,
  brandAssetsURL: string.isRequired,
  marketingBannerURL: string.isRequired,
  showCaptcha: bool.isRequired,
  showGoogleFooterLink: bool.isRequired,
  disclaimerEnabled: bool.isRequired,
  identityProviders: object
}

export default LoginPage
