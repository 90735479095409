import React from 'react'
import { string, object, bool, number } from 'prop-types'
import redirect from '../../utils/redirect'
import * as steps from '../../constants/steps'
import { LoginPage, MFA, MFASelector } from '../../pages'
import { LoginContext } from '../../contexts/Login'
import '../../../assets/css/index.css'

class Root extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: steps.LOGIN_FORM,
      loginTimestamp: this.currentTimeSeconds(),
      selectedMFA: null,
      mfas: [],
      credentials: {
        username: '',
        password: ''
      },
      updateCredentials: this.handleCredentialsChange,
      changeStep: this.changeStep,
      setMFAs: this.setMFAs,
      selectMFA: this.selectMFA
    }
  }

  changeStep = step => {
    this.setState({ step })
  }

  selectMFA = mfaType => {
    this.setState({
      selectedMFA: this.state.mfas.find(mfa => mfa.type === mfaType)
    })
  }

  setMFAs = mfas => {
    this.setState({ mfas, loginTimestamp: this.currentTimeSeconds() })
  }

  currentTimeSeconds = () => new Date().getTime() / 1000

  handleCredentialsChange = (field, value) => {
    this.setState(state => {
      return {
        credentials: {
          ...state.credentials,
          [field]: value
        }
      }
    })
  }

  getSelectedMFA = () => this.state.selectedMFA || this.state.mfas[0]

  renderStep = step => {
    return {
      [steps.LOGIN_FORM]: <LoginPage {...this.props} />,
      [steps.MFA]: (
        <MFA
          mfa={this.getSelectedMFA()}
          loginTimestamp={this.state.loginTimestamp}
          changeStep={this.state.changeStep}
          redirect={redirect}
          {...this.props}
        />
      ),
      [steps.MFA_SELECTOR]: (
        <MFASelector
          mfas={this.state.mfas}
          changeStep={this.state.changeStep}
          selectMFA={this.state.selectMFA}
          redirect={redirect}
          {...this.props}
        />
      )
    }[step]
  }

  render() {
    return (
      <LoginContext.Provider value={this.state}>
        {this.renderStep(this.state.step)}
      </LoginContext.Provider>
    )
  }
}

Root.propTypes = {
  csrf: object.isRequired,
  sidebannerEnabled: bool.isRequired,
  localLoginEnabled: bool.isRequired,
  identityProviders: object,
  showCaptcha: bool,
  showGoogleFooterLink: bool,
  disclaimerEnabled: bool,
  siteKey: string.isRequired,
  brandAssetsURL: string.isRequired,
  brandingSettings: object.isRequired,
  talkdeskIdAssetsURL: string.isRequired,
  marketingBannerURL: string.isRequired,
  marketingBannerTimeout: number.isRequired,
  metadata: object.isRequired
}

export default Root
